@import "../../../data/constants";

.tsr__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: center
  padding-top: 2vh;
  padding-bottom: 2rem; }

.tsr__rail__container {
  position: relative;
  width: 80%;
  height: 2rem;
  margin: 0 1.4rem 0 2rem; }

.tsr__rail {
  background: $black;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: .7rem;
  border-radius: 33px;
  &__selected {
    background: $gradient; } }

.tsr__handle {
  background-color: $white;
  color: $black;
  // border: 1px solid $black
  min-width: 3rem;
  white-space: nowrap;
  max-height: 3.1rem;
  border-radius: 33px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: $fontSize-r;
  padding: .5rem 1rem;
  cursor: pointer; }
