@import "../../../data/constants.sass";

.calendar__container {
  border: 2px solid $black;
  border-left: none;
  border-right: none;
  font-size: $fontSize-r;
  overflow: hidden;
  background-color: $white;
  width: 100%; }

.calendar__arrow {
  cursor: pointer;
  font-size: 1.5rem;
  color: $white;
  text-align: center;
  transition: all .3s ease-out;
  &:hover {
    color: $black; } }

.calendar__header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: $gradient;
  height: 4rem; }

.calendar__grid {
  display: grid;
  grid-template: repeat(7,auto) / repeat(7, auto); }

.calendar__month {
  font-weight: bold;
  color: $white;
  text-align: center;
  padding: .25rem .12rem;
  word-spacing: .5rem;
  user-select: none; }

.calendar__day {
  text-align: center;
  align-self: center;
  border: 2px solid $grey;
  border-left: none;
  height: 4rem;
  color: $black;
  background: $grey;
  padding: .9rem .12rem;
  letter-spacing: .3rem;
  font-weight: bold; }


.calendar__grid .calendar__day:nth-child(7) {
  border-right: none; }

.calendar__grid .calendar__date:nth-of-type(7n) {
  border-right: none; }

.calendar__grid .calendar__date:nth-last-of-type(-n+7) {
  border-bottom: none; }

.calendar__date {
  height: 4rem;
  text-align: center;
  align-self: center;
  letter-spacing: .1rem;
  padding: .9rem .12rem;
  user-select: none;
  color: $black;
  border-bottom: 2px solid $grey;
  border-right: 2px solid $grey;
  cursor: pointer;
  transition: all .4s ease-out;
  &:hover {
    color: $pink;
    background: lighten($grey, 30); }
  &--is-highlighted {
    @extend .calendar__date;
    color: #fff;
    background: $gradient;
    position: relative; }
  &--is-today {
    @extend .calendar__date;
    color: $black;
    background: transparent;
    border-color: $black;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      border-bottom: 1.5rem solid $black;
      border-left: 1.5rem solid transparent;
      border-top: 1.5rem solid transparent; }
    &::before {
      content: '';
      position: absolute;
      left: -2px;
      top: -2px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      border-top: 2px solid;
      border-left: 2px solid; } }
  &--is-other {
    @extend .calendar__date;
    color: $grey; } }
