@import "../../../data/constants";

.cf__container {
  background-color: $grey;
  width: 95%;
  align-self: flex-end;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  padding: 1rem;
  & h1 {
    color: $black; }
  @include breakpoint($desktop) {
    width: 80%;
    padding: 2rem; } }


.cf__boxes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.cf__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }
