$pink: #FF3289;
$blue: #71C1D9;
$black: #171717;
$grey: #d5d5d5;
$white: #f9fcf3;
$gradient: linear-gradient(to right, $pink 0%, $blue 100%);

$font: "Ubuntu", sans-serif;
$fontSize-s: 1.4rem;
$fontSize-r: 1.6rem;
$fontSize-xs: 1.1rem;

$desktop: 720px;


@mixin breakpoint($point) {
  @media (min-width: $point) {
    @content; } }

@mixin listNone {
  list-style: none;
  margin: 0;
  padding: 0; }
