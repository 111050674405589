@import "../../../data/constants";

.mb__button {
  z-index: 4;
  position: fixed;
  bottom: 7rem;
  right: 3%;
  background: $gradient;
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  border: 3px solid $black;
  display: flex;
  -webkit-tap-highlight-color: transparent; }

.mb__content {
  margin: auto;
  color: $white;
  background-color: $black;
  border-radius: 50%;
  width: 65%;
  height: 65%;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-out; }

.mb__button:hover .mb__content {
  background: transparent; }
