@import "../../../data/constants";

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type=number] {
  -moz-appearance: textfield; }

.addscreen__container {
  background: $white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 5;
  padding: 5% 10%;
  & h1 {
    font-size: 2.5rem; } }

.addscreen__header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative; }

.addscreen__close {
  font-size: 15px;
  position: absolute;
  right: .5rem; }

.addscreen__form {
  display: flex;
  flex-direction: column;
  justify-content: space-around; }

.addscreen__input {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: $fontSize-s;
  margin-bottom: 2vh;
  & .addscreen__input--field {
    background: transparent;
    outline: none;
    border: none;
    min-width: 100px;
    font-size: $fontSize-s;
    box-shadow: none; }
  & .addscreen__label {
    background: $gradient;
    border-top-left-radius: 33px;
    border-bottom-left-radius: 33px;
    padding: .5rem 1rem .5rem 2rem;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 3.4rem; } }

.addscreen__input__container {
  border: 2px solid $blue;
  border-top-right-radius: 33px;
  border-bottom-right-radius: 33px;
  border-left: none;
  padding: .5rem 1rem .5rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 3.4rem;
  width: 50%; }

.addscreen__input .datepicker__container {
  width: 100%;
  margin-right: 0; }

.addscreen__input .datepicker__input {
  width: 50%; }

.addscreen__remove {
  font-size: 1rem; }
