@import "../../../data/constants";

.li__outer_container {
  position: relative;
  border-bottom: 1px solid $grey; }

.li__container {
  @include listNone;
  z-index: 1;
  font-size: $fontSize-s;
  display: flex;
  flex-direction: row;
  height: 6rem;
  justify-content: space-between;
  padding: 2% 3%;
  position: relative;
  align-items: center;
  cursor: pointer;
  background: $white;
  transition: width 0.4s;
  width: 100%;
  &--is-active {
    background: $gradient;
    width: 75%;
    & .li__descr, & .li__cat {
      overflow: hidden;
      text-overflow: ellipsis; } }
  @include breakpoint($desktop) {
    height:  auto; } }

@mixin li__item($flex-basis) {
  text-align: left;
  flex-basis: $flex-basis; }

.li__descr, .li__cat {
  @include li__item(28%);
  flex-shrink: 6;
  text-overflow: inherit;
  overflow: hidden;
  transition: all 0.4s; }

.li__amount {
  @include li__item(20%);
  font-weight: bold;
  text-align: right;
  margin-left: auto;
  &--header {
    font-weight: normal; } }

.li__avatar {
  @include li__item(12%);
  padding: 0 4% 0 2%; }

.li__date {
  @include li__item(12%);
  text-align: center;
  flex-shrink: 0; }

.li__settings {
  position: absolute;
  background: $black;
  width: 25%;
  height: 100%;
  top: 0;
  right: 0;
  color: $white;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  &-item {
    margin: auto;
    height: 50%;
    width: 50%;
    align-self: flex-end; } }


.li__header {
  z-index: 2;
  position: fixed;
  top: 0;
  width: 100%;
  height: 5rem;
  text-align: left;
  cursor: pointer;
  &-li {
    display: flex;
    flex-direction: row;
    padding: 1% 3%;
    background: $black;
    color: $white;
    font-size: $fontSize-s;
    height: 100%;
    align-items: center; }
  &-date {
    flex-basis: 12%;
    text-align: center; }
  &-user {
    flex-basis: 12%;
    padding: 0 4% 0 2%; }
  &-description {
    flex-basis: 28%; }
  &-category {
    flex-basis: 28%; }
  &-amount {
    flex-basis: 20%;
    text-align: right; }
  &-bold {
    font-weight: bold; }
  &-reverse {
    transform: rotateX(180deg); } }

.svg_edit {
  width: 100%;
  height: 100%;
  transform: rotate(37deg);
  @include breakpoint($desktop) {
    width: 50%;
    height: 50%; } }
