@import "../../../data/constants";

.autosuggest {
  display: flex;
  flex-direction: column; }

.autosuggest__input {
  position: relative; }

.autosuggest__container {
  background-color: $black;
  font-size: $fontSize-xs;
  // max-height: 10rem
  list-style: none;
  width: 15rem;
  color: $white;
  padding: 0;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 2.2rem; }

.autosuggest__suggestion {
  padding: .3rem .5rem;
  cursor: pointer;
  background: $black;
  border-bottom: 1px solid $grey;
  &--is-active, &:hover {
    @extend .autosuggest__suggestion;
    background: $gradient; } }
