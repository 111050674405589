@import "../../../data/constants.sass";

.userselection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: $grey;
  border-radius: 33px;
  width: 100%;
  padding-left: 3vw;
  font-size: $fontSize-s;
  & h3 {
    font-weight: 400;
    font-size: $fontSize-s;
    margin: 0; } }

.userselection__subcontainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; }


.selection__input {
  border: none;
  outline: none;
  border-radius: 33px;
  background-color: $white;
  height: 3.6rem;
  padding: 0 1rem;
  font-size: $fontSize-s; }
