@import "../../../data/constants.sass";

$button-size: 2.5em;
$check-height: $button-size/2;
$check-width: $check-height/2;
$check-left: $button-size/6;

.checkbutton {
  font-size: 12px;
  margin: .5em;
  width: $button-size;
  height: $button-size;
  border-radius: 50%;
  position: relative;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  transition: all .3s ease-out;
  &--black {
    background-color: $black; }
  &--pink {
    background-color: $pink; }
  &--blue {
    background-color: $blue; }
  &--gradient {
    background: $gradient; }
  &:hover {
    background-color: $white; } }

.checkbutton__inner {
  background-color: transparent;
  &::after {
    opacity: 1;
    width: $check-width;
    height: $check-height;
    transform-origin: left top;
    border-right: 2px solid $black;
    border-top: 2px solid $black;
    content: '';
    top: $check-height;
    left: $check-left;
    position: absolute;
    transform: scaleX(-1) rotate(135deg); } }


.checkbutton:hover .checkbutton__inner::after, .checkbutton__inner--is-active::after {
  border-top-color: $pink;
  border-right-color: $pink;
  animation-play-state: running;
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: checkmark;
  animation-iteration-count: 1; }


@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1; }

  20% {
    height: 0;
    width: $check-width;
    opacity: 1; }

  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1; }

  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1; } }

.checkbutton--is-active {
  background-color: $white; }
