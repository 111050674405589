.ali__button {
  width: 100%;
  height: auto;
  min-height: 11rem;
  padding: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10rem;
  flex-grow: 5;
  margin: auto;
  & div {
    margin: auto;
    opacity: .3; } }
