@import "../../../data/constants";

.el__container {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column; }

.el__content {
  margin-top: 5rem;
  margin-bottom: 6rem;
  min-height: 100%; }
