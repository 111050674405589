@import "../../../data/constants";

.close__container {
  cursor: pointer;
  margin: auto;
  width: 3em;
  height: 3em;
  padding: .5em 0;
  &:hover {
    .close__content:nth-child(1) {
      transform: rotate(135deg) translateX(0.6em); }
    .close__content:nth-child(2) {
      transform: rotate(45deg) translateX(-0.6em); } } }

.close__content {
  width: 2em;
  height: .2em;
  margin: .6em auto;
  transition: all 0.3s ease-in-out;
  display: block;
  &:nth-child(1) {
    transform: rotate(45deg) translateY(0.6em); }
  &:nth-child(2) {
    transform: rotate(-45deg) translateY(-0.6em); } }
