@import "../../../data/constants";

.menu__container {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: $black;
  z-index: 3;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: $white;
  padding: 0;
  padding-top: 3vh;
  &-enter {
    opacity: 0.5;
    transform: translateX(100%);
    &-active {
      opacity: 1;
      transform: translateX(0);
      transition: all 0.5s cubic-bezier(0,1,.88,.98); } }
  &-exit {
    opacity: 1;
    &-active {
      transition: all 0.5s cubic-bezier(1,0,.88,.98);
      opacity: 0;
      transform: translateX(100%); } } }
