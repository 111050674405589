@import "../../../data/constants";

.fullLogo__container {
  display: flex;
  flex-direction: row;
  font-size: 4.5rem;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative; }

.fullLogo__text {
  text-transform: lowercase; }

.fullLogo__img {
  display: inline-block;
  height: 2.5rem;
  width: 2.5rem;
  margin: auto;
  margin-bottom: 0.9rem; }
