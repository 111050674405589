@import "../../../data/constants";

.cb__container {
  margin: .3rem 0;
  display: flex;
  flex-direction: row-reverse;
  -webkit-tap-highlight-color: transparent;
  &:last-of-type {
    margin-bottom: .6rem; }
  @include breakpoint($desktop) {
    margin: 1rem 0; } }


.cb__box {
  opacity: 0;
  margin: 0;
  z-index: 1;
  &:checked {
    & + .cb__label {
      color: $white;
      &::before {
        opacity: 1; } } }
  &:active {
    color: lime; } }

.cb__label-imgBox {
  width: 3rem;
  height: 3rem;
  margin: 0.3rem 0.5rem 0.3rem 0.3rem;
  z-index: 1; }

.cb__label {
  font-size: $fontSize-s;
  border: 2px solid transparent;
  cursor: pointer;
  background-color: $white;
  border-radius: 33px;
  color: $black;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 1rem;
  position: relative;
  height: 3.6rem;
  transition: 0.2s ease all;
  z-index: 0;
  &--user {
    padding-left: 0; }
  &::before {
    background: $gradient;
    opacity: 0;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 33px;
    z-index: -1;
    transition: 0.2s ease all; } }


.cb__box:focus + .cb__label {
  border: 2px solid $blue; }
