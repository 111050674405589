@import "../../../data/constants";

.rf__container {
  background-color: $grey;
  width: 95%;
  align-self: flex-end;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  padding: 1rem;
  & h1 {
    color: $black; }
  @include breakpoint($desktop) {
    width: 80%;
    padding: 2rem; } }

.rf__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }
