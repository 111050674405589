.filterheader__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

.filterheader__buttons {
  display: flex;
  justify-content: space-around;
  align-items: center; }
