@import "./data/constants";

@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: local('Ubuntu-Regular'), url(./data/fonts/Ubuntu-Regular.ttf) format('ttf'); }

@font-face {
  font-family: 'Ubuntu';
  font-style: bold;
  font-weight: 600;
  src: local('Ubuntu-Bold'), url(./data/fonts/Ubuntu-Bold.ttf) format('ttf'); }

@font-face {
  font-family: 'Ubuntu';
  font-style: ligth;
  font-weight: 200;
  src: local('Ubuntu-Light'), url(./data/fonts/Ubuntu-Light.ttf) format('ttf'); }

* {
  box-sizing: border-box; }

html {
  font-size: 10px; }

body {
  margin: 0;
  padding: 0;
  font-size: 10px;
  font-family: $font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $white;
  box-sizing: border-box;
  min-height: 100vh; }

.main {
  min-height: 100vh; }

button {
  outline: none;
  border: none;
  background-color: transparent;
  font-family: $font;
  font-size: 10px;
  cursor: pointer; }

.colouredDivider {
  height: 3px;
  width: 100%;
  background: $gradient; }

.bg_white {
  background-color: $white; }

.bg_black {
  background-color: $black; }

.bg_grey {
  background-color: $grey; }
