@import "../../../data/constants.sass";

.datefilter__container {
  background-color: $grey;
  width: 95%;
  align-self: flex-end;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  padding: 1rem;
  & h1 {
    color: $black; }
  @include breakpoint($desktop) {
    width: 80%;
    padding: 2rem; } }

.datefilter__inner {
  display: flex;
  flex-direction: column;
  @include breakpoint($desktop) {
    display: flex;
    flex-direction: row;
    justify-content: space-between; } }

.datefilter__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

.datefilter__buttons {
  display: flex;
  justify-content: space-around;
  align-items: center; }
