@import "../../../data/constants";

.burgerIcon {
  cursor: pointer;
  margin: auto;
  width: 3rem;
  height: 3rem;
  z-index: 4; }

.burgerIcon__line {
  width: 2.8rem;
  height: 0.2rem;
  background-color: $white;
  margin: 0.6rem auto;
  transition: all 0.3s ease-in-out;
  display: block; }

.burgerIcon--is-active .burgerIcon__line:nth-child(2) {
  opacity: 0; }

.burgerIcon--is-active .burgerIcon__line:nth-child(1) {
  transform: translateY(0.9rem) rotate(45deg); }

.burgerIcon--is-active .burgerIcon__line:nth-child(3) {
  transform: translateY(-0.7rem) rotate(-45deg); }
