@import "../../../data/constants";

@mixin buttonDefault {
  border-radius: 33px;
  font-size: $fontSize-r;
  padding: 0.5rem 1rem;
  transition: all 0.2s ease; }

.regularButton {
  @include buttonDefault;
  background: $white;
  border: 2px solid $black;
  color: $black;
  &:hover {
    color: $white;
    background: $black; } }

.regularButton-invert {
  @include buttonDefault;
  background: $black;
  border: 2px solid $white;
  color: $white;
  &:hover {
    color: $black;
    background: $white; } }

.acceptButton__container {
  background: $gradient;
  border-radius: 33px;
  padding: 2px;
  &:hover .acceptButton {
    background: transparent;
    color: $white; } }

.acceptButton {
  @include buttonDefault;
  border: 0px;
  color: $black;
  background: $white; }

.twoStep {
  @include buttonDefault;
  background-color: $white;
  border: 2px solid $pink;
  color: $pink;
  &--is-active {
    @include buttonDefault;
    background: $gradient;
    color: $white; } }
