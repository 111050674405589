@import "../../../data/constants.sass";

.reloadbutton {
  width: 2rem;
  height: 2rem;
  position: relative;
  transition: all .3s ease-out;
  cursor: pointer;
  &::before, &::after {
    content: '';
    display: block; }
  &::before {
    border-color: $black;
    border-left-color: transparent;
    border-radius: 50%;
    border-width: .25rem;
    border-style: solid;
    transform: rotate(45deg);
    height: 1.5rem;
    width: 1.5rem;
    transition: all .3s ease-out; }
  &::after {
    border-right-color: $black;
    border-style: solid;
    border-width: .4rem .77rem .4rem 0;
    position: absolute;
    right: 50%;
    transform: translateY(-25%);
    top: 0;
    background-color: transparent;
    color: transparent;
    transition: all .3s ease-out; }
  &:hover {
    transform: rotate(-45deg);
    &::after {
      border-right-color: $pink; }
    &::before {
      border-color: transparent;
      border-top-color: $pink;
      border-right-color: $pink; } } }
