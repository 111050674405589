@import "../../../data/constants.sass";

.datepicker__container {
  width: 100%;
  font-size: $fontSize-s;
  margin-bottom: .5em;
  transition: all .3s;
  @include breakpoint($desktop) {
    margin-right: 2rem; } }

.datepicker__form {
  display: flex;
  flex-direction: row; }

.datepicker__label {
  background: $gradient;
  padding: .5rem 1rem .5rem 2rem;
  border-top-left-radius: 33px;
  border-bottom-left-radius: 33px;
  min-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white; }

.datepicker__input {
  border: 2px solid $blue;
  border-top-right-radius: 33px;
  border-bottom-right-radius: 33px;
  background: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1rem;
  font-size: 10px; }

.datepicker__input--field {
  padding: .5rem 0 .5rem 1rem;
  font-size: $fontSize-s;
  text-transform: lowercase;
  border: none;
  background: transparent;
  width: 90%; }

.datepicker__error {
  color: $pink;
  padding-left: 2rem;
  padding-right: 1rem;
  &::before {
    content: 'FEHLER! ';
    font-weight: bold;
    letter-spacing: .1em; } }

.calendarpick__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  & h2 {
    color: $black; } }

.calendarpick__header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative; }

.calendarpick__close {
  align-self: flex-end;
  position: absolute;
  top: .5rem;
  right: .5rem; }
