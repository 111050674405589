@import "../../../data/constants";

.pp__avatar-img {
  border-radius: 50%;
  width: 100%;
  margin: auto;
  min-height: 3rem;
  min-width: 3rem;
  max-height: 5rem;
  max-width: 5rem;
  border: 3px solid $white;
  @include breakpoint($desktop) {
    height: 100%; } }
