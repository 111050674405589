@import "../../../data/constants";

.lb__outer {
  width: 100%;
  height: 6rem;
  position: fixed;
  bottom: 0;
  z-index: 3; }


.lb__container {
  background-color: $black;
  color: $white;
  font-size: $fontSize-r;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0% 3%;
  height: 100%; }


.lb__users {
  overflow: scroll;
  display: flex;
  flex-direction: row;
  flex-basis: 70%;
  height: 70%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-left: $white solid 1px;
  @include breakpoint($desktop) {
    overflow: hidden; } }

.lb__user {
  border-right: $white solid 1px;
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 2rem;
  &-sum {
    margin: auto;
    padding-left: 2rem;
    font-weight: bold;
    &Name {
      font-weight: 400;
      font-size: $fontSize-xs;
      display: block; } } }

.lb__sum {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-left: 3px solid $white;
  text-align: right;
  font-weight: bold;
  padding-left: 2rem;
  &-all {
    font-weight: 400;
    font-size: $fontSize-xs;
    padding-top: 0.6rem; } }
